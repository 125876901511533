import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

const StyledPagination = styled.nav`
  & .nav-links {
    display: grid;
    align-items: center;
    font-size: var(--f6);
    grid-auto-flow: column;
    align-items: center;
    font-size: var(--f5);
  }

  & a {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    align-items: center;
    padding: 15px 30px;
    border: solid 1px currentColor;
  }

  & .prev {
    justify-self: start;
  }

  & .next {
    justify-self: end;
  }
`

export const Pagination = ({ pages, first, current, preSlug, last }) => {
  return (
    <>
      {pages > 1 && (
        <StyledPagination role="navigation" aria-label="投稿">
          <h2 className="screen-reader-text">投稿ナビゲーション</h2>
          <div className="nav-links">
            {!first && (
              <Link
                to={
                  current === 2 ? `/${preSlug}/` : `/${preSlug}/${current - 1}/`
                }
                rel="prev"
                className="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            )}

            {!last && (
              <Link
                to={`/${preSlug}/${current + 1}/`}
                rel="next"
                className="next"
              >
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )}
          </div>
        </StyledPagination>
      )}
    </>
  )
}

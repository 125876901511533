import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Alignwide, MyContainer, MyGrid } from "../components/styles"
import { PostHead } from "../components/postHead"
import { Pagination } from "../components/pagination"
import { OtherImage } from "../components/otherImage"
import { Sidebar } from "../components/sidebar"
import { FixedPost, StyledReadMore } from "../components/fixedPost"

const StyledPostListWrapper = styled.main`
  ${MyContainer}
  row-gap: 30px;
  padding: 30px 0;

  > div {
    ${Alignwide}
    ${MyGrid}
    row-gap: 30px;
  }
`

const StyledPostList = styled.div`
  display: grid;
  row-gap: 30px;

  @media (min-width: 768px) {
    grid-column: 1 / span 8 !important;
    align-content: start;
  }
`

const StyledArticleLink = styled.article`
  display: grid;
  row-gap: 30px;
  background-color: var(--white);
  padding: 30px 0;

  p {
    margin: 0;
    padding: 0 10px;
    font-size: var(--f5);
  }

  @media (min-width: 768px) {
    p {
      font-size: var(--f4);
      padding: 0 30px;
    }
  }
`

const StyledFigureLink = styled(Link)`
  &:hover {
    opacity: 1;
  }
`

const PostList = ({ data, pageContext, location }) => {
  return (
    <Layout>
      <Seo pagepath={location.pathname} />
      <StyledPostListWrapper>
        <div>
          <FixedPost />
        </div>
        <div>
          <StyledPostList>
            {data.allWpPost.edges.map(({ node }) => {
              return (
                <StyledArticleLink key={node.id}>
                  <PostHead
                    date={node.date}
                    dateJP={node.dateJP}
                    modified={node.modified}
                    modifiedJP={node.modifiedJP}
                    slug={`/blog/${node.slug}/`}
                    title={node.title}
                    categories={node.categories.nodes}
                  />
                  {node.featuredImage ? (
                    <StyledFigureLink to={`/blog/${node.slug}/`}>
                      <figure>
                        {/* <img
                          src={node.featuredImage.node.sourceUrl}
                          alt={node.title}
                          width={node.featuredImage.node.mediaDetails.width}
                          height={node.featuredImage.node.mediaDetails.height}
                        /> */}
                        <GatsbyImage
                          image={
                            node.featuredImage.node.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt=""
                          style={{ height: "100%" }}
                        />
                      </figure>
                    </StyledFigureLink>
                  ) : (
                    <StyledFigureLink to={`/blog/${node.slug}/`}>
                      <OtherImage />
                    </StyledFigureLink>
                  )}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.excerpt,
                    }}
                  />

                  <StyledReadMore to={`/blog/${node.slug}/`}>
                    記事を読む
                  </StyledReadMore>
                </StyledArticleLink>
              )
            })}

            <Pagination
              pages={pageContext.pages}
              first={pageContext.isFirst}
              current={pageContext.currentPage}
              preSlug={`blog`}
              last={pageContext.isLast}
            />
          </StyledPostList>

          <Sidebar />
        </div>
      </StyledPostListWrapper>
    </Layout>
  )
}

export default PostList

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpPost(sort: { order: DESC, fields: date }, skip: $skip, limit: $limit) {
      edges {
        node {
          id
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              sourceUrl
              title
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          date
          dateJP: date(formatString: "YYYY/MM/DD")
          modified
          modifiedJP: modified(formatString: "YYYY/MM/DD")
          title
          excerpt
          categories {
            nodes {
              id
              slug
              name
            }
          }
        }
      }
    }
  }
`
